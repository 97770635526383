
import { Component, Vue } from 'vue-property-decorator';
import DateUtils from '@/domain/Utils/DateUtils';

@Component
export default class UpdateDataIntoComponent extends Vue {
  private dateUtils = DateUtils;

  private updateDate = '';

  created() {
    this.updateDate = this.dateUtils.getDateUpdateDataInfo();
  }
}
