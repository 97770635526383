
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Card extends Vue {
  @Prop({ default: '' }) title!: string;

  @Prop({ default: '' }) subtitle!: string;

  @Prop({ default: '' }) text!: string;

  @Prop({
    type: Boolean,
  }) private loading!: boolean;

  @Prop() fillColor!: string;
}
