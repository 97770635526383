
import { Component, Vue, Prop } from 'vue-property-decorator';
import BusinessUnit from '@/domain/businessUnit/BusinessUnit';
import Card from './Card.vue';

@Component({
  components: { Card },
})
export default class Cards extends Vue {
  @Prop() businessUnit!: BusinessUnit;

  @Prop() businessUnitFirstMonth!: BusinessUnit;

  @Prop() month!: number;

  @Prop() year!: number;

  @Prop() yearBeforeCompanyValue!: string;

  @Prop() monthName!: string;

  @Prop({
    type: Boolean,
  }) loading!: boolean;

  @Prop() fillColor!: string;
}
