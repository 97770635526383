
import { Component, Prop, Vue } from 'vue-property-decorator';
import BusinessUnit from '@/domain/businessUnit/BusinessUnit';
import BusinessUnitService from '@/domain/businessUnit/BusinessUnitService';
import Cards from '@/components/Cards.vue';
import ShareValueChart from '@/components/ShareValueChart.vue';
import BusinessUnitViewFilter from './businessUnitViewFilter.vue';

@Component({
  components: {
    Cards,
    ShareValueChart,
    BusinessUnitViewFilter,
  },
})
export default class Anymarket extends Vue {
  @Prop({ default: '' }) readonly businessUnitName!: string;

  @Prop({ default: '' }) readonly logoSrc!: string;

  @Prop({ default: '' }) readonly logoSrcDark!: string;

  @Prop({ default: '' }) readonly fillColor!: string;

  @Prop({ default: '' }) readonly filterButtonColor!: string;

  @Prop({ default: '' }) readonly filterButtonTextColor!: string;

  @Prop({ default: [] }) readonly observations!: string[];

  @Prop({
    type: Boolean,
    default: false,
  }) private showBusinessUnitTitle!: boolean;

  private loading = false;

  businessUnit: BusinessUnit = BusinessUnit.of({});

  businessUnitFirstMonth: BusinessUnit = BusinessUnit.of({});

  yearNow: number = BusinessUnitService.getYearNow();

  month = BusinessUnitService.getMonthNow() + 1;

  year = this.yearNow;

  monthName = BusinessUnitService.getMonthNameNow();

  businessUnitLastYearValue = '';

  async getBusinessUnitDataWithFilterProps(props: any) {
    this.loading = true;
    this.monthName = BusinessUnitService.getMonthName(props.month);
    this.month = props.month;
    this.year = props.year;
    this.businessUnitLastYearValue = await BusinessUnitService
      .getlastYearBusinessUnitValue(this.businessUnitName, props.year);
    this.businessUnitFirstMonth = await BusinessUnitService
      .getFirstMonthBusinessUnitData(this.businessUnitName, props.year);
    this.businessUnit = await BusinessUnitService
      .getDataByBusinessUnitMonthAndYear(this.businessUnitName, props.month, props.year);
    this.loading = false;
  }

  get getDarkmodeKey() {
    return this.$store.state.darkmodeKey;
  }

  async created() {
    this.loading = true;
    this.businessUnitLastYearValue = await BusinessUnitService
      .getlastYearBusinessUnitValue(this.businessUnitName, this.yearNow);
    this.businessUnitFirstMonth = await BusinessUnitService
      .getFirstMonthBusinessUnitData(this.businessUnitName, this.yearNow);
    this.businessUnit = await BusinessUnitService
      .getMonthNowBusinessUnitData(this.businessUnitName, this.yearNow);
    this.loading = false;
  }
}
