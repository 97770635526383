import Vue from 'vue';
import msal from 'vue-msal';

Vue.use(msal, {
  auth: {
    tenantId: process.env.VUE_APP_TENANT_ID,
    clientId: process.env.VUE_APP_CLIENT_ID,
    redirectUri: process.env.VUE_APP_REDIRECT_URL,
    autoRefreshToken: true,
  },
});
