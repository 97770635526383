
import { Component, Vue } from 'vue-property-decorator';
import BusinessUnitMain from '../components/BusinessUnitMain.vue';

@Component({
  components: { BusinessUnitMain },
})
export default class HomeView extends Vue {
  businessUnitName = 'DB1GROUP';

  fillColor = 'rgba(3,153,182,75%)';

  filterButtonColor = 'rgba(0,51,94,90%)';

  filterButtonTextColor = 'white--text';

  logoSrc = 'db1_group_logo.png';

  logoSrcDark = 'db1_group_dark_logo.png';

  observations = [
    '* Valores mensais para acompanhamento de variações, não oficiais. Valores oficiais serão disponibilizados em janeiro, após fechamento contábil do exercício anterior.',
  ]
}
