
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Navgation extends Vue {
  drawer = false;

  permanent = null;

  darkmode = localStorage.getItem('darkmode') === 'true';

  setDarkMode() {
    if (this.darkmode) {
      localStorage.setItem('darkmode', 'true');
      this.$vuetify.theme.dark = true;
    } else {
      localStorage.removeItem('darkmode');
      this.$vuetify.theme.dark = false;
    }
    this.$store.commit('darkmodeRerender');
  }
}
