
import BusinessUnitService from '@/domain/businessUnit/BusinessUnitService';
import { Vue, Component, Prop } from 'vue-property-decorator';
import UpdateDataInfo from '@/components/UpdateDataInfo.vue';

@Component({
  components: { UpdateDataInfo },
})
export default class BusinessUnitViewFilter extends Vue {
  @Prop() filterButtonColor!: string;

  @Prop({
    type: Boolean,
  }) private loading!: boolean;

  yearNow: number = BusinessUnitService.getYearNow();

  year: number = this.yearNow;

  years: number[] = BusinessUnitViewFilter.range(this.yearNow, 2021, -1);

  month: number = BusinessUnitService.getMonthNow() + 1;

  months: number[] = this.year === this.yearNow
    ? BusinessUnitViewFilter.range(1, BusinessUnitService.getMonthNow() + 1, 1)
    : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  public getMonths() {
    if (this.year === this.yearNow) {
      if (this.month > BusinessUnitService.getMonthNow()) {
        this.month = BusinessUnitService.getMonthNow() + 1;
        this.filter();
      }
      this.months = BusinessUnitViewFilter.range(1, BusinessUnitService.getMonthNow() + 1, 1);
    } else {
      this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }
  }

  filter() {
    return this.$emit('filterCards', {
      month: this.month,
      year: this.year,
    });
  }

  private static range(start: number, stop: number, step: number) {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
  }
}
