import moment from 'moment';

const getDateUpdateDataInfo = () => {
  const dayNow = moment().date();
  const month = moment().month();
  const year = moment().year();
  if (dayNow < 20) {
    return `20/${month + 1}/${year}`;
  }
  return `20/${month + 2}/${year}`;
};

export default {
  getDateUpdateDataInfo,
};
