export default class User {
  private data: any;

  constructor(data: any) {
    this.data = data;
  }

  static ofEmpty() {
    return new User({});
  }

  static of(data: any) {
    return new User(data);
  }

  get name() {
    return this.data.name;
  }

  get userName() {
    return this.data.userName;
  }
}
