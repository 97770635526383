import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/consignet',
    name: 'Consignet',
    component: () => import('../views/ConsignetView.vue'),
  },
  {
    path: '/anymarket',
    name: 'Anymarket',
    component: () => import('../views/AnymarketView.vue'),
  },
  {
    path: '/koncili',
    name: 'Koncili',
    component: () => import('../views/KonciliView.vue'),
  },
  {
    path: '/predize',
    name: 'Predize',
    component: () => import('../views/PredizeView.vue'),
  },
  {
    path: '/ducz',
    name: 'Ducz',
    component: () => import('../views/DuczView.vue'),
  },
  {
    path: '/db1Global',
    name: 'DB1 Global',
    component: () => import('../views/Db1GlobalView.vue'),
  },
  {
    path: '/tinbot',
    name: 'TinBot',
    component: () => import('../views/TinbotView.vue'),
  },
  {
    path: '/mykids',
    name: 'MyKids',
    component: () => import('../views/MykidsView.vue'),
  },
  {
    path: '/lughy',
    name: 'LUGHY',
    component: () => import('../views/LughyView.vue'),
  },
  {
    path: '/summary',
    name: 'Summary',
    component: () => import('../views/AllCompaniesView.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
