import User from './user';

interface Msal {
  data: any;
  isAuthenticated(): boolean;
  signIn(): any;
  signOut(): any;
  acquireToken(): Promise<string>;
  msGraph(endpoins: any[]): Promise<any>;
}

export default class AuthenticationService {
  static msal: Msal;

  static login(): void {
    if (this.nonAuthenticated()) {
      this.msal.signIn();
    }
  }

  static logout(): void {
    if (this.isAuthenticated()) {
      this.msal.signOut();
    }
  }

  static isAuthenticated(): boolean {
    return this.msal.isAuthenticated();
  }

  static nonAuthenticated(): boolean {
    return !this.isAuthenticated();
  }

  static userLoggedIn(): User {
    if (this.isAuthenticated()) {
      return User.of(this.msal.data.user);
    }
    return User.ofEmpty();
  }

  static token(): Promise<string> {
    return this.msal.acquireToken();
  }
}
