
import BusinessUnitService from '@/domain/businessUnit/BusinessUnitService';
import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class ChartFilter extends Vue {
  @Prop() filterButtonColor!: string

  @Prop({ default: '' }) readonly filterButtonTextColor!: string

  @Prop({
    type: Boolean,
  }) private loading!: boolean;

  yearNow: number = BusinessUnitService.getYearNow();

  yearEnd: number = this.yearNow;

  yearStart: number = (BusinessUnitService.getMonthNow() + 1) === 12
    ? this.yearNow : this.yearNow - 1;

  yearsEnd: number[] = ChartFilter.range(this.yearNow, 2021, -1);

  yearsStart: number[] = ChartFilter.range(this.yearNow, 2021, -1);

  monthEnd: number = BusinessUnitService.getMonthNow() + 1;

  monthStart: number = this.monthEnd === 12 ? 1 : this.monthEnd + 1;

  monthsEnd: number[] = this.yearEnd === this.yearNow
    ? ChartFilter.range(1, BusinessUnitService.getMonthNow() + 1, 1)
    : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  monthsStart: number[] = this.yearStart === this.yearNow
    ? ChartFilter.range(1, BusinessUnitService.getMonthNow() + 1, 1)
    : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  public getMonthsEnd() {
    if (this.yearEnd === this.yearNow) {
      if (this.monthEnd > BusinessUnitService.getMonthNow()) {
        this.monthEnd = BusinessUnitService.getMonthNow() + 1;
      }
      this.monthsEnd = ChartFilter.range(1, BusinessUnitService.getMonthNow() + 1, 1);
    } else {
      this.monthsEnd = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }
  }

  public getMonthsStart() {
    if (this.yearStart === this.yearNow) {
      if (this.monthStart > BusinessUnitService.getMonthNow()) {
        this.monthStart = BusinessUnitService.getMonthNow() + 1;
      }
      this.monthsStart = ChartFilter.range(1, BusinessUnitService.getMonthNow() + 1, 1);
    } else {
      this.monthsStart = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }
  }

  filter() {
    return this.$emit('filter', {
      monthStart: this.monthStart,
      yearStart: this.yearStart,
      monthEnd: this.monthEnd,
      yearEnd: this.yearEnd,
    });
  }

  private static range(start: number, stop: number, step: number) {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
  }
}
