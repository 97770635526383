
import { Component, Prop, Vue } from 'vue-property-decorator';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart, LineController, LineElement, PointElement, CategoryScale, LinearScale, Filler,
} from 'chart.js';
import BusinessUnitService from '@/domain/businessUnit/BusinessUnitService';
import ChartFilter from './ChartFilter.vue';

Chart.register(LineController, LineElement, PointElement, CategoryScale, LinearScale);

@Component({
  components: { ChartFilter },
})

export default class ShareValueChart extends Vue {
  @Prop({ default: '' }) readonly businessUnitName!: string

  @Prop({ default: '' }) readonly fillColor!: string

  @Prop({ default: '' }) readonly filterButtonColor!: string

  @Prop({ default: '' }) readonly filterButtonTextColor!: string

  private loading = false;

  chart: any = null;

  snackbar = false;

  vuetify = this.$vuetify;

  updateChart(values: number[], labels: string[]) {
    this.chart.data.datasets[0].data = values;
    this.chart.data.labels = labels;
    this.chart.update();
  }

  private static range(start: number, stop: number, step: number) {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
  }

  async createLast12MonthsChart() {
    this.loading = true;
    const currentYearAllData = await BusinessUnitService
      .getAllDataByBusinessUnitAndYear(this.businessUnitName, BusinessUnitService.getYearNow());
    const lastYearAllData = await BusinessUnitService
      .getAllDataByBusinessUnitAndYear(this.businessUnitName, BusinessUnitService.getYearNow() - 1);
    const last12MonthsAllData = [...lastYearAllData, ...currentYearAllData].slice(-12);
    const values: number[] = [];
    const labels: string[] = [];
    last12MonthsAllData.forEach((data) => {
      const value = data.vlacao;
      const label = `${(`00${data.mes}`).slice(-2)}/${data.ano.slice(-2)}`;
      values.push(value);
      labels.push(label);
    });
    this.updateChart(values, labels);
    this.loading = false;
  }

  createChartWithFilterProps({
    monthStart, yearStart, monthEnd, yearEnd,
  }: any) {
    const startDate = new Date(yearStart, monthStart - 1);
    const endDate = new Date(yearEnd, monthEnd - 1);
    if (startDate.getTime() <= endDate.getTime()) {
      this.createChartByInterval(
        monthStart,
        yearStart,
        monthEnd,
        yearEnd,
      );
    } else {
      this.snackbar = true;
    }
  }

  createChart(chartData: object) {
    const canvas = document.getElementById('lineChart') as HTMLCanvasElement;
    const isDarkmode = localStorage.getItem('darkmode') === 'true';
    const config: any = {
      type: 'line',
      data: chartData,
      plugins: [ChartDataLabels, Filler],
      options: {
        plugins: {
          datalabels: {
            formatter: (value: number) => BusinessUnitService.formatCurrencyToBRL(value),
            font: {
              size: this.getResponsiveFontSize(),
            },
            color: isDarkmode ? 'white' : '#666666',
          },
        },
        layout: {
          padding: {
            right: this.vuetify.breakpoint.mdAndUp ? 32 : 48,
          },
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: this.getResponsiveFontSize(),
              },
              color: isDarkmode ? 'white' : '#666666',
            },
            grid: {
              color: isDarkmode ? '#555' : '#e5e5e5',
            },
          },
          y: {
            ticks: {
              font: {
                size: this.getResponsiveFontSize(),
              },
              color: isDarkmode ? 'white' : '#666666',
            },
            grid: {
              color: isDarkmode ? '#555' : '#e5e5e5',
            },
          },
        },
      },
    };

    return new Chart(canvas, config);
  }

  public async createChartByInterval(
    monthStart: number,
    yearStart: number,
    monthEnd: number,
    yearEnd: number,
  ) {
    const years = ShareValueChart.range(yearStart, yearEnd, 1);
    this.loading = true;
    const allDataArray = await Promise.all(years.map(async (year) => BusinessUnitService
      .getAllDataByBusinessUnitAndYear(this.businessUnitName, year)));
    const allData = allDataArray.flat();
    const firstData = allData[0];
    const firsDataDate = new Date(firstData.ano, firstData.mes - 1);
    const startDate = new Date(yearStart, monthStart - 1);
    let startDiff;
    if (firstData.mes === 1) {
      startDiff = monthStart - 1;
    } else if (firsDataDate.getTime() > startDate.getTime()) {
      startDiff = 0;
    } else {
      startDiff = monthStart - firstData.mes;
    }
    const endDiff = (BusinessUnitService.getYearNow()) === yearEnd
      ? BusinessUnitService.getMonthNow() + 1 - monthEnd
      : 12 - monthEnd;
    const filteredData = allData
      .slice(startDiff, allData.length - (endDiff));
    const values: number[] = [];
    const labels: string[] = [];

    filteredData.forEach((data) => {
      const value = data.vlacao;
      const label = `${(`00${data.mes}`).slice(-2)}/${data.ano.slice(-2)}`;
      values.push(value);
      labels.push(label);
    });
    this.updateChart(values, labels);
    this.loading = false;
  }

  getResponsiveFontSize() {
    if (this.vuetify.breakpoint.xsOnly) {
      return 12;
    }
    if (this.vuetify.breakpoint.smAndUp) {
      return 16;
    }
    return 14;
  }

  mounted() {
    this.chart = this.createChart({
      datasets: [
        {
          data: [],
          fill: {
            target: 'origin',
            above: this.fillColor,
          },
        },
      ],
      labels: [],
    });
    this.createLast12MonthsChart();
  }
}
