
import { Vue, Component } from 'vue-property-decorator';
import AuthenticationService from '@/domain/secutiry/authentication.service';
import Navigation from '@/components/Navgation.vue';

@Component({
  components: {
    Navigation,
  },
})
export default class App extends Vue {
  created() {
    AuthenticationService.msal = this.$msal;
    AuthenticationService.login();
    this.darkModeByParam();
  }

  mounted() {
    this.getDarkmode();
  }

  // eslint-disable-next-line class-methods-use-this
  get isAuthenticated() {
    return AuthenticationService.isAuthenticated();
  }

  getDarkmode() {
    const theme = localStorage.getItem('darkmode');
    if (theme === 'true') {
      this.$vuetify.theme.dark = true;
      return;
    }
    this.$vuetify.theme.dark = false;
  }

  darkModeByParam() {
    if (this.$route.query.darkmode === 'true') {
      localStorage.setItem('darkmode', 'true');
    }
    if (this.$route.query.darkmode === 'false') {
      localStorage.removeItem('darkmode');
    }
  }
}

