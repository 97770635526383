export default class BusinessUnit {
  ano: string;

  ebtidaqlt12meses: number;

  infcalculo: string;

  mes: number;

  nmempresa: string;

  pccrescanoant: number;

  pcvar12: number;

  pcvarano: number;

  pcvarmes: number;

  qtacoes: string;

  tpcalculo: string;

  vlacao: string;

  vlempresa: string;

  vlrecbrut12meses: number;

  vlrecbrutaanoant: number;

  vlrecliq12meses: number;

  vlresult12meses: number;

  constructor(obj: any) {
    this.ano = obj.ano || '';
    this.ebtidaqlt12meses = obj.ebtidaqlt12meses || null;
    this.infcalculo = obj.infcalculo || '';
    this.mes = obj.mes || null;
    this.nmempresa = obj.nmempresa || '';
    this.pccrescanoant = obj.pccrescanoant || null;
    this.pcvar12 = obj.pcvar12 || null;
    this.pcvarano = obj.pcvarano || null;
    this.pcvarmes = obj.pcvarmes || null;
    this.qtacoes = obj.qtacoes || null;
    this.tpcalculo = obj.tpcalculo || null;
    this.vlacao = obj.vlacao || null;
    this.vlempresa = obj.vlempresa || null;
    this.vlrecbrut12meses = obj.vlrecbrut12meses || null;
    this.vlrecbrutaanoant = obj.vlrecbrutaanoant || null;
    this.vlrecliq12meses = obj.vlrecliq12meses || null;
    this.vlresult12meses = obj.vlresult12meses || null;
  }

  public static of(obj: any): BusinessUnit {
    return new BusinessUnit(obj || {});
  }

  public static ofList(obj: any): BusinessUnit[] {
    return obj.map(this.of);
  }
}
